/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}
